
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import Building from "@/components/reusable/policy-information/house-insurance/OwnedBuilding.vue";

export default defineComponent({
  name: "underwriter-owned-building-insurance-policy-information",
  components: { GoBackButton, Building },
  props: ["publicId"],
  data() {
    return {
      policyDetailsSummary: {},
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Owned Building", [
        "Policies",
        "Policy Information",
        "House Insurance",
      ]);
    });
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get the details of the vehicle in schdule list
    this.getOwnedBuildingDetails();

    //Set page title
    document.title =
      "Underwriter: Owned Building Insurance Policy Details | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getOwnedBuildingDetails() {
      const router = useRouter();
      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.UNDERWRITERS_FOLIO_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policyDetailsSummary = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
  },
});
